<template>
  <div class="at-tooltip">
    <span
      ref="trigger"
      class="at-tooltip__trigger">
      <slot />
    </span>

    <transition
      :name="transition"
      @after-leave="doDestory">
      <div
        v-if="show"
        ref="popover"
        class="at-tooltip__popper"
        :class="[
          placement ? 'at-tooltip--' + placement : 'at-tooltip--top'
        ]">
        <div class="at-tooltip__arrow" />
        <div class="at-tooltip__content">
          <slot name="content">
            <div v-html="computedContent" />
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PopoverMixin from './mixin_popover';
export default {
  name: 'CallTooltip',
  mixins: [PopoverMixin],
  props: {
    trigger: {
      type: String,
      default: 'hover'
    },
    transition: {
      type: String,
      default: 'fade'
    }
  },
  computed: {
    computedContent () {
      return this.content.replace(/\n/g, '<br/>');
    }
  }
};
</script>
