import Notification from '../components/common/notification';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CallTooltip from '../components/common/tooltip/CallTooltip';
import Vue from 'vue';

Vue.component('DatePicker', DatePicker);
Vue.component('Notification', Notification);
Vue.component('CallTooltip', CallTooltip);

Vue.prototype.$Notify = Notification;
